@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
:root {
  --navy: #123C5F;
  --blue: #117894;
  --yellow: #FFCB30;
  --light-blue: #BAF0F5;
  --green: #1AC572;
  --red: #D62828;
  --text-dark: #123C5F;
  --text-light: #ffffff;
  --light-grey: #ECECEC;
}

html {
  overflow-x: hidden;
  max-width: 100vw;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-weight: 500;
  font-size: 17px;
  color: var(--text-dark);
}

body, input, button {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-dark);
}

.elevated {
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.25));
  -webkit-filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.25));
}